import NavLink from "@liberetech/design-system/NavLink";
import useRipple from "@liberetech/design-system/useRipple";
import classNames from "classnames";
import { useRouter } from "next/router";
import React, { useRef } from "react";

import LayoutContactIcon from "../LayoutContactIcon/LayoutContactIcon";

import { useDevice } from "components/Context/PageContext";
import { useTranslation } from "lib/i18n/client";

import styles from "./LayoutNavBar.module.css";

const LayoutNavBar: React.FC<LayoutNavBarProps> = ({
  keyButton,
  onMenuClick,
  onContactClick,
}) => {
  const { t } = useTranslation("common");
  const { pathname } = useRouter();
  const { notifyNativeApp } = useDevice();
  return (
    <nav className={styles.navbar}>
      <ul className={styles.navbarItems}>
        <li>
          <LayoutNavBarButton
            title={t("navbar.explore")}
            icon={
              <svg viewBox="0 0 25 24">
                <path d="M23 22.5l-6.38-6.38" />
                <path d="M10.25 18.75a9 9 0 100-18 9 9 0 000 18z" />
              </svg>
            }
            href="/"
            selected={
              pathname === "/" ||
              pathname.includes("/asset") ||
              pathname.includes("/destination")
            }
          />
        </li>
        <li>
          <LayoutNavBarButton
            title={t("navbar.contact")}
            icon={<LayoutContactIcon />}
            onClick={() => onContactClick()}
          />
        </li>
        <li>
          <LayoutNavBarButton
            title={t("navbar.myplace")}
            icon={
              <svg viewBox="0 0 24 24">
                <path d="M19.15 20.69c-.14-2.45-1.53-3.22-3.57-3.9-1.46-.48-1.93-1.96-2.08-2.85M10.5 13.94c-.16.9-.62 2.37-2.08 2.85-2.04.68-3.44 1.45-3.58 3.89" />
                <path d="M12 14.25a3.75 3.75 0 01-3.75-3.75V9a3.75 3.75 0 017.5 0v1.5A3.75 3.75 0 0112 14.25z" />
                <path d="M23.24 12.53A11.25 11.25 0 10.76 11.47a11.25 11.25 0 0022.48 1.06z" />
              </svg>
            }
            selected={
              pathname.startsWith("/myplace") || pathname.startsWith("/login")
            }
            href="/myplace"
          />
        </li>
        {keyButton && (
          <li>
            <LayoutNavBarButton
              title={t("navbar.key")}
              icon={
                <svg viewBox="0 0 24 24">
                  <path d="m12.76 13.62 1.31-1.31v-.9l-1.9-2.62-1.89 2.62V22.5A10.55 10.55 0 0 1 1.5 12.07C1.5 6.23 6.2 1.5 12 1.5s10.5 4.73 10.5 10.57c0 5.13-3.62 9.4-8.43 10.37v-4.87l-1.26-1.32 1.26-1.32-1.3-1.3Z" />
                  <path d="M16.2 16.25A5.9 5.9 0 0 0 12.15 6.1 5.9 5.9 0 0 0 8.1 16.25" />
                </svg>
              }
              onClick={() => {
                notifyNativeApp("keyring_opened");
              }}
            />
          </li>
        )}
        <li>
          <LayoutNavBarButton
            title={t("navbar.menu")}
            icon={
              <svg viewBox="0 0 25 24">
                <path d="M2 12h21M12.5 5.25H23M2 18.75h10.5" />
              </svg>
            }
            onClick={() => {
              onMenuClick();
            }}
          />
        </li>
      </ul>
    </nav>
  );
};

const LayoutNavBarButton: React.FC<LayoutNavBarButtonProps> = ({
  icon,
  title,
  onClick,
  href,
  selected,
}) => {
  const Component = href ? NavLink : "button";
  const ref = useRef(null);
  useRipple(ref);
  return (
    <Component onClick={onClick} href={href}>
      <div
        ref={ref}
        className={classNames(styles.navbarItem, {
          [styles.navbarItemSelected]: selected,
        })}
      >
        {icon}
        {title}
      </div>
    </Component>
  );
};

type LayoutNavBarProps = {
  keyButton: boolean;
  onMenuClick: () => void;
  onContactClick: () => void;
};

type LayoutNavBarButtonProps = {
  title: string;
  icon: React.ReactNode;
  onClick?: () => void;
  href?: string;
  selected?: boolean;
};

export default LayoutNavBar;
