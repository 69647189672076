// Code generated by codegen. DO NOT EDIT!
//
// Use 'yarn codegen' to regenerate this file based on schema and
// file defined operations.

import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

import * as Types from "../../gql/types";

import { fetcher } from "lib/client/graphql";
export type SessionQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SessionQuery = {
  __typename?: "Query";
  viewerV2: {
    __typename?: "Viewer";
    id: string;
    account:
      | { __typename: "Company"; id: string }
      | { __typename: "Inhabitant"; id: string }
      | { __typename: "TravelAgency"; id: string }
      | null;
  } | null;
};

export const SessionDocument = `
    query Session {
  viewerV2 {
    id
    account {
      id
      __typename
    }
  }
}
    `;
export const useSessionQuery = <TData = SessionQuery, TError = unknown>(
  variables?: SessionQueryVariables,
  options?: UseQueryOptions<SessionQuery, TError, TData>,
) =>
  useQuery<SessionQuery, TError, TData>(
    variables === undefined ? ["Session"] : ["Session", variables],
    fetcher<SessionQuery, SessionQueryVariables>(SessionDocument, variables),
    options,
  );

useSessionQuery.getKey = (variables?: SessionQueryVariables) =>
  variables === undefined ? ["Session"] : ["Session", variables];
export const useInfiniteSessionQuery = <TData = SessionQuery, TError = unknown>(
  pageParamKey: keyof SessionQueryVariables,
  variables?: SessionQueryVariables,
  options?: UseInfiniteQueryOptions<SessionQuery, TError, TData>,
) => {
  return useInfiniteQuery<SessionQuery, TError, TData>(
    variables === undefined
      ? ["Session.infinite"]
      : ["Session.infinite", variables],
    (metaData) =>
      fetcher<SessionQuery, SessionQueryVariables>(SessionDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options,
  );
};

useInfiniteSessionQuery.getKey = (variables?: SessionQueryVariables) =>
  variables === undefined
    ? ["Session.infinite"]
    : ["Session.infinite", variables];
