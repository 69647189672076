import { nanoid } from "nanoid";
import { createContext, useContext, useEffect, useState } from "react";

import { fetcher } from "lib/client/graphql";
import useBroadcastChannel from "lib/useBroadcastChannel";

import {
  SessionDocument,
  SessionQuery,
  SessionQueryVariables,
} from "./SessionContext.generated";

const SessionContext = createContext<ContextType>({
  session: null,
  refresh: () => Promise.resolve(),
});

const COOKIE_ANON_ID = "li_anon";
const COOKIE_EXPIRATION = 3600 * 1000 * 24 * 365; // 1 year

export const Provider: React.FC = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);

  const refresh = async () => {
    try {
      const { viewerV2 } = await fetcher<SessionQuery, SessionQueryVariables>(
        SessionDocument,
      )();
      setSession({
        inhabitantId: viewerV2?.id,
        anonId: getAnonId(),
        accountId: viewerV2?.account?.id,
        accountType: viewerV2?.account?.__typename.toUpperCase() as AccountType,
      });
    } catch {
      setSession({ anonId: getAnonId() });
    }
  };

  const channel = useBroadcastChannel({
    name: "sessionRefresh",
    onMessage: refresh,
  });

  useEffect(() => {
    (async () => {
      await refresh();
    })();
  }, []);

  return (
    <SessionContext.Provider
      value={{
        session,
        refresh: async () => {
          await refresh();
          channel.post("refreshed");
        },
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

const getAnonId = (): string => {
  const [, id] =
    document.cookie.match(new RegExp(`(?:^| )${COOKIE_ANON_ID}=([^;]+)`)) || [];

  if (id) {
    return id;
  }

  const newId = nanoid();
  const expiration = new Date();
  expiration.setTime(expiration.getTime() + COOKIE_EXPIRATION);

  document.cookie = `${COOKIE_ANON_ID}=${newId};expires=${expiration.toUTCString()};Path=/;`;

  return newId;
};

export const useSession: () => ContextType = () => {
  const session = useContext(SessionContext);
  return session;
};

type ContextType = { session: Session | null; refresh: () => Promise<void> };
export type AccountType = "INHABITANT" | "COMPANY" | "TRAVEL_AGENCY";

export type Session = {
  anonId: string;
  inhabitantId?: string;
  accountType?: AccountType;
  accountId?: string;
};
